.App {
  text-align: center;
  white-space: nowrap;
}

.Alert {
  text-align: left;
  /* bottom: 0%;
  right: 0%;
  position: fixed; */
}

.card-s_title {
  font-size: 25;
  padding: 5;
  height: 55;
  color: white
}

.btn-sm2 {
  padding: .15rem .15rem;
  font-size: .8rem;
}

/* .vessel {
  background: rgb(128,128,128) !important;
} THIS WORKS!! */ 

/* .TargetTemp {
  right: 50%;
  position: fixed;
} */

/* font-weight: bold;
font-size: 35px;
text-align: center; */
    